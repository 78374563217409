import fetch from './req';

/**
 * 公告列表
*/
export function getNoticeList (query) {
  return fetch({
    url: '/notice/list',
    method: 'get',
    params: query
  });
}

/**
 * 公告分类(RUD)
*/
export function changeCategory (params) {
  return fetch({
    url: '/notice/category/change',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

/**
 * 公告分类(下拉框和维护回显)
*/
export function getCategory () {
  return fetch({
    url: '/notice/category/list',
    method: 'get'
  });
}

/**
 * 新增或修改保存公告信息
*/
export function createOrUpNotice (params) {
  return fetch({
    url: '/notice/createOrUpdate',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
