<template>
  <div class="notice-detail">
    <div class="title-bar">
      <div class="title">
        新建通知公告
      </div>
      <div class="btn-action">
        <el-button type="primary">
          发布
        </el-button>
        <el-button>
          保存
        </el-button>
        <el-button @click="cancel">
          取消
        </el-button>
      </div>
    </div>
    <div class="form-container">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="140px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" size="small" style="width: 600px;" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="选择分类" prop="typeName">
          <el-select v-model="form.typeName" placeholder="请选择" size="small" style="width: 460px;">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="发布范围" prop="rangeType">
          <el-select v-model="form.rangeType" placeholder="请选择" size="small" style="width: 460px;">
            <el-option
              v-for="item in rangeTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-show="form.rangeType" label="配置范围" prop="rangeText">
          <el-input v-model="form.rangeText" size="small" style="width: 460px;" />
          <el-button type="text" style="margin-left:12px;" @click="viewRangeDialog = true">
            +添加
          </el-button>
        </el-form-item>
        <el-form-item label="有效时间" prop="startTime">
          <el-date-picker
            v-model="form.startTime"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 228px;"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
          />
          <span>-</span>
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 228px;"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          <span class="time-text">结束时间为空需手动终止</span>
        </el-form-item>
        <el-form-item label="是否置顶" prop="rangeText">
          <el-radio-group v-model="form.isTop">
            <el-radio :label="1">
              是
            </el-radio>
            <el-radio :label="0">
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="form.isTop" label="置顶天数" prop="daysToTop">
          <el-input v-model="form.daysToTop" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" size="small" style="width: 460px;" />
        </el-form-item>
        <el-form-item label="附件" prop="file">
          <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
              <div> 支持扩展名：.PDF .PNG .JPG .XLSX .DOC .DOCX .MP4 .ZIP .URL .TXT .XLS .JPEG .GIF .PPTX .ET .PAGES </div>
            </div>
            <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
          </el-upload>
        </el-form-item>
        <el-form-item label="公告内容" prop="content">
          <QuillEditor />
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="配置发布范围"
      :visible.sync="viewRangeDialog"
      append-to-body
      width="50%"
      custom-class="viewRangeDialog"
    >
      <div class="content">
        <div class="tree">
          <el-input
            v-model="filterText" 
            placeholder="搜索"
            prefix-icon="el-icon-search"
            size="small"
          />
          <el-tree
            ref="tree"
            :data="treeData"
            :props="{label: 'name'}"
            :default-checked-keys="defaultCheckedIds"
            :default-expand-all="false"
            :node-key="'id'"
            show-checkbox
            style="margin-top: 6px"
            :filter-node-method="filterNode"
          >
            <template #default="{data}">
              <span :id="data.id">{{ data.name }}</span>
            </template>
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="canclePermission">取 消</el-button>
        <el-button size="small" type="primary" @click="addPermission">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCategory, createOrUpNotice
} from '@/api/notice';
import { getDepartmentList } from '@/api/department';
import QuillEditor from '@/components/quill-editor';
export default {
  components: {
    QuillEditor,
  },
  data () {
    return {
      activeName: 'login',
      form: {
        title: '',
        typeName: '',
        rangeType: 0,
        rangeText: '',
        startTime: '',
        endTime: '',
        file: null,
        isTop: 0,
        daysToTop: null,
        content: '',
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        typeName: [
          { required: true, message: '请选择分类', trigger: 'blur' },
        ],
        rangeText: [
          { required: true, message: '请选择配置范围', trigger: 'blur' },
        ],
        daysToTop: [
          { required: true, message: '请输入置顶天数', trigger: 'blur' },
        ],
        startTime: [
          { required: true, message: '请选择有效时间', trigger: 'blur' },
        ],
      },
      rangeTypeList: [
        { label: '全员可见', value: 0 },
        { label: '指定部门/人员可见', value: 1 },
      ],
      typeList: [],
      viewRangeDialog: false,
      treeData: [],
      defaultCheckedIds: [],
      filterText: '',
    };
  },
  mounted () {
    this.init();
    this.getCategory();
  },
  methods: {
    init () {
      this.form.startTime = this.getDateStr(0);
      let params = {
        pageNo: 1,
        pageSize: 10000
      };
      getDepartmentList(params).then(res => {
        const { list } = res;
        this.treeData = list;
      });
    },
    async getCategory () {
      const res = await getCategory();
      this.typeList = res;
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    canclePermission () {
      this.viewRangeDialog = false;
    },
    addPermission () {
      // const ids = this.$refs.tree.getCheckedKeys();
      // this.setbizList(this.treeData, ids);
      this.viewRangeDialog = false;
    },
    handleClick () { },
    onSubmit (formName) {
    },
    getDateStr (AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1);//获取当前月份的日期，不足10补0
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();//获取当前几号，不足10补0
      var hours = dd.getHours(); //获取当前小时
      var minutes = dd.getMinutes(); //获取当前分钟
      var seconds = dd.getSeconds(); //获取当前秒
      return y + "-" + m + "-" + d + ' ' + hours + ":" + minutes + ":" + seconds;
    },
    cancel () {
      this.$confirm('通知公告尚未保存，确定要离开吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({ path: '/notice-manage' });
      }).catch(() => { });
    },
  },
};
</script>
<style scoped lang="scss">
.notice-detail {
  background-color: #fff;
  padding: 24px;
  .title-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    .title {
      font-size: 18px;
      color: #333;
      line-height: 32px;
      font-weight: 500;
    }
    .btn-action {
    }
  }
  .time-text {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 20px;
    padding-left: 9px;
  }
}
.viewRangeDialog {
  .content {
    min-height: 400px;
    max-height: 50vh;
    overflow: auto;
  }
}
</style>
<style lang="scss">
.notice-detail {
  .el-upload-dragger {
    width: 600px;
  }
}
</style>