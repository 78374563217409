import fetch from './req';

export function getDepartmentList (query) {
  return fetch({
    url: '/department/list',
    method: 'get',
    params: query
  });
}
export function createDepartment (data) {
  return fetch({
    url: '/department/create',
    method: 'post',
    data
  });
}
export function updateDepartment (data) {
  return fetch({
    url: '/department/update',
    method: 'post',
    data
  });
}
export function deleteDepartment (data) {
  return fetch({
    url: '/department/delete',
    method: 'post',
    data
  });
}
